/* VignetteView.css */

/* Overall styles for the license plate container */

.vignette-view {
  display: flex;
  width: 100%;
  height: 180px; 
  background-color: #e1f2ec; /* Adjust background color */
  border-radius: 10px; /* Adjust border radius for roundness */
  border: 2px dotted black;
  padding: 2px;
}

.vig-rectangle {
  display: flex;
  flex-direction: column; 
  /* align-items: center; */
  /* justify-content: center; */
  padding: 5px;
  border-radius: 10px; /* Adjust border radius for roundness */
}

.vig-first-rectangle {
  flex: 0.2; /* Set width to 30% */
  background-color: #f0f0f0; /* Adjust background color */
}

.vig-second-rectangle {
  flex: 1; /* Take remaining space */
  background-color: #e0e0e0; /* Adjust background color */
}

.vignette-row {
  display: flex; /* Allow side-by-side placement of label and value */
  margin: 0 0 5px 0;
}

.vignette-row > * { /* Target all child elements within .vignette-row */
  flex: 1; /* Make all child elements flexible for resizing */
}

.vignette-row > .vig-row-col1 { /* Target the element with class "vig-label" */
  flex: 0 0 50%; /* Set width to 30% with no minimum or maximum flex */
  text-align: right; /* Align label text to the right */
}

.vignette-row > .vig-row-col2 { /* Target the element with class "vig-value" */
  flex: 1; /* Allow the value to flex and fill remaining space */
}

.vig-row-col2 {
  padding-left: 5px;
}

.vig-value,
.vig-label {
  font-size: 12px;
}

.vig-value {
  flex: 50%;
  font-weight: bold; /* Differentiate label and value */
  text-align: left;
}

.vig-dotted-line {
  flex: 0 0 1px; /* Set width to 1px */
  margin: 0 10px; /* Add margin for spacing */
  border: 2px dashed #ccc; /* Create dotted line */
}

.vig-label {
  flex: 50%;
  margin-right: 10px;
  text-align: right;
}

.vig-vertical-text {
  writing-mode: vertical-lr; /* Rotate text vertically */
  text-align: center; /* Center text horizontally */
  white-space: nowrap; /* Prevent text wrapping */
  display: flex;
  align-items: center; 
  padding-left: 15px;
  justify-content: center; 
  font-size: 12px;
  font-weight: 800;
}

.vig-top-label {
  text-align: right; /* Center text horizontally */
  margin: 0px; /* Add spacing between label and middle section */
  font-weight: bold; /* Differentiate label and value */
}

.vig-top-section {
  flex: 1; /* Make top and bottom sections each 20% */
  display: flex;
  align-items: flex-start;
  justify-content: left;
  max-width: 70%; /* Adjust image size as needed */
}

.vig-bottom-section {
  flex: 1; /* Make top and bottom sections each 20% */
  display: flex;
  align-items: center;
  justify-content: center;
}

.vig-middle-section {
  flex: 3; /* Make middle section take remaining 60% */
  padding-top: 5px;
}

.vig-top-section img {
  max-width: 100%; /* Adjust image size as needed */
  max-height: 100%;
}

.vig-bottom-section img {
  max-width: 100%; /* Adjust image size as needed */
  max-height: 100%;
}

.vignette-close-button {
   cursor: pointer;
   background-image: url(../../public/assets/images/close-arrow.png);
   width: 32px;
   height: 32px;
   padding-bottom: 5px;
   float: right;
}

.vehicle-heading {
  border-bottom: 2px solid black; /* Adjust line style and color */
  padding-bottom: 5px; /* Add optional padding for spacing */
}

.image-title {
  padding: 0;
  margin: 0;
}

@media (max-width: 600px) {
  .vignette-row {
    font-size: 8px;
  }
    .vig-value,
    .vig-label {
        font-size: 8px;
    }
  }
