@import 'react-toastify/dist/ReactToastify.css';
@import "react-datepicker/dist/react-datepicker.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fb_iframe_widget span{
  width: 1000px!important;
}
.my-iframe-class ._2p3a{
  width: 1000px!important;
}
.fb_iframe_widget iframe{
  width: 1000px!important;
}

.color-fill{
  background-color: #e1f2ec!important;
}

/* .twm-s-info-inner .css-13cymwt-control{
  background-color : #f0f6fe!important;
  border-color: #f0f6fe!important;
}
.twm-s-info-inner .css-b62m3t-container, .twm-s-info-inner .css-3iigni-container{
  padding : 11px!important;
} */
.css-13cymwt-control{
  border-color: #ffffff!important;
  box-shadow: 0 0 0 1px #fff!important;
}
.css-t3ipsp-control{
  border-color: #ffffff!important;
  box-shadow: 0 0 0 1px #fff!important;
}
.css-t3ipsp-control:hover{
  border-color: #ffffff!important;
  box-shadow: 0 0 0 1px #fff!important;
}
.css-1nmdiq5-menu{
  z-index: 10000000000!important;
}
.css-1u9des2-indicatorSeparator{
  display: none!important;
}
.form-label {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin: 0;
}
.twm-nav-list-1 .active {
  color: #214236;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 25px rgba(180, 205, 237, 0.7);
}
.btn-disabled{
  background-color: silver;
  pointer-events: none;
}
.required{
  font-size: 12px;
  color: red;
  vertical-align: top;
}
.pointer{
  cursor: pointer;
}
.headerBtn{
  background: rgb(255 255 255 / 0%);
  border: 0;
  font-size: 16px;
}
.text-them{
  color: #63c29f;
}
.font-semibold{
  color: #63c29f;
  font-weight: bold;
}
.active-font-semibold{
  color: #fff!important;
  font-weight: bold;
}
.active-bg-semibold{
  color: red!important;
  font-weight: bold;
}

.modal-language .flag{
  background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 0.375rem;
    border-width: 1px;
    height: 1.75rem;
    width: 2rem;
    margin-right: 10px;
}
.check-btn{
  height: 98px;
  min-width: 116px;
}
.weight-box{
  font-size: 12px;
  color: rgb(255, 0, 122);
  border: 1px dotted rgba(0,0,0,.08);
  border-radius: 8px;
  padding: 6px 13px 4px 10px;
  background: white;
  box-shadow: rgb(0 0 0 / 10%) 0 1px 3px 1px;
}
.select-border{
  border: 2px solid #d7dddb;
  border-radius: 6px;
}
.select-border .css-19s3ub1-control{
  border: none!important;
}
.selectInput{
  padding: 11px;
}
.css-olqui2-singleValue{
  color:#343434!important;
}
.paypal-button{
  background: #ffc439;
  height: 55px;
  vertical-align: top;
  min-height: 40px;
  max-height: 55px;
  width: 100%;
  border-radius: 4px
}
.paypal-button:hover{
  background-color: #ffc439;
}
.list-group-item.active{
  background-color: rgb(255 255 255 / 0%);
  border: none;
  color: white;
}
.divider-1{
  position: absolute;
  top: 27px;
  bottom: 0px;
  height: 3px;
  width: 50%;
  display: block;
  background-color: #214236;
  right: 0;
}
.divider-2{
  position: absolute;
  top: 27px;
  bottom: 0px;
  height: 3px;
  width: 100%;
  display: block;
  background-color: #214236;
  left: 0;
}
.divider-3{
  position: absolute;
  top: 27px;
  bottom: 0px;
  height: 3px;
  width: 100%;
  display: block;
  background-color: #214236;
  left: 0;
}
.divider-4{
  position: absolute;
  top: 27px;
  bottom: 0px;
  height: 3px;
  width: 50%;
  display: block;
  background-color: #214236;
  left: 0;
}
.step-btn{
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px;
  font-weight: 600;
  transition-duration: 200ms;
  /* border-radius: 1000px;
  height: 3rem;
  width: 3rem; */
  border-radius: 11px;
  height: 2.5rem;
  width: 9.1rem;
  background: #214236;
  position: relative;
  z-index: 1;
  color: white;
  box-shadow: 0px 4px 11px -3px #214236;
}
.list-group-item.active > .step-btn{
  background: #63c29f !important;
}
.step-arrow{
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -18px;
  color: #214236;
  display: none;
}
.stripe-logo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hello-user{
  font-weight: bold;
  font-size: 13px;
  color: #214236;
}
.list-group-item.active > .step-arrow{
  display: block;
}
.wt-panel-body .list-group-horizontal>.list-group-item+.list-group-item{
  border: 0;
}
.wt-panel-body .list-group-horizontal>.list-group-item:first-child{
  border: 0;
}

.twm-nav-user{
  background-color: rgb(33, 66, 54)!important;
  padding: 4px 12px!important;
  font-size: 22px!important;
}
.sub-loading {
  background: #00000078;
  position: fixed;
  z-index: 99999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mobile-step-btn{
  width: 2.5rem !important;
}
.grid-container {
  display: grid;
  grid-template-columns: 100px 100px 100px 100px 100px;
  column-gap: 10px;
 
}
.grid-container-home{
  display: grid;
  grid-template-columns: 180px 180px 180px 180px;
  column-gap: 20px;
}
.license-plate-flag{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #055cc6;
    height: 60px;
    width: 40px;
    left: 0;
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border: none;
}

.license-plate-flag .country-name{
  padding-top: 1px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  font-size: .875em;
}
.category-5-slider .swiper-button-next{
  position: absolute !important;
    top: 0px !important;
    right: -30px !important;
    margin: 70px 30px;
    /* color: red !important; */
    /* background-color: black !important; */
    opacity: 0.6;
    
}
.category-5-slider .swiper-button-prev{
  position: absolute !important;
    top: 0px !important;
    left: -30px !important;
    margin: 70px 30px;
    /* color: red !important;
    background-color: black !important; */
    opacity: 0.6;
}
/* .courtyactv{
  visibility: hidden;
} */
.signinupposition{
  position: absolute !important;
  left: -58px !important;
  right: 0 !important;
}
.pay_inp_p{
  padding-left: 30px !important;
}

/* Set the color of the pagination buttons */
.react-paginate ul{
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-paginate a {
  color: #333;
  box-shadow: 0px 3px 20px #0000000b;
  position: relative;
  border-radius: 0.375rem;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  min-width: 40px;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-weight: 500;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

/* Set the active page button background color */
.react-paginate .selected a {
  background-color: #164e63;
  color: #fff;
}
.react-paginate ul li a{
  background-color: #e0e9e6;
}
.react-paginate ul li{
  list-style-type: none !important;
}
.activeborder{
  background-color: #63c29f;
  width: 25% !important;
}
.active-tab{
  background-color: #63c29f!important;
}
.fa-paswd-show-hide{
  right: 2%;
  top: 36%;
  cursor: pointer;
  font-size: 20px;
}
.stripe-pay-formcontrol{
  border-radius: 5px !important;
  border: 1px solid rgb(183, 188, 191) !important;
  background-color: white !important;
  padding: 15px 10px !important;
  height: 50px !important;
  
}
.pading_paycard{
padding-left:  35px !important;
}
.feather-credit-card{
  top:-3px !important;
  
}
.paypalbtn{
  height: 40px;
  overflow: hidden;
}
.headerbg{
  background-color: #e1f2ec !important;
}


@media only screen and (max-width: 441px){
 .grid-containerd {
    display: grid;
    grid-template-columns: 100px 100px!important;
    column-gap: 10px;
  }
}

@media only screen and (max-width: 576px){
  .mobile-p-remove{
    padding: 0 !important;
  }
  .mobile-m-order{
    margin-top: 0 !important;
    margin-top: 30px !important;
  }
  .grid-container-homed{
    display: grid;
    grid-template-columns: 450px;
    column-gap: 20px;
  }
  .item2{
    display: none;
  }

}




@media only screen and (min-width: 1400px){
  .wt-listing-full-width .accordion-item{
    margin-right: 52px;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 500px){
  .paypal-button-container{
    max-width: 100%!important;
  }
}
@media only screen and (max-width: 768px){
  /* .check-btn{
    height: 100px;
    min-width: 120px;
  } */
  /* .slide_mobileview{
    margin-top: 55px;
  } */
  .twm-nav-user{
    padding: 2px 13px!important;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 100px 100px 100px !important;
    column-gap: 10px;
   
  }
  .grid-container-home{
    display: grid;
    grid-template-columns: 250px 250px !important;
    column-gap: 20px;
    
  }
  .item3{
    display: none;
  }
  

  
}




@media only screen and (max-width: 992px){
  .mo_view_flexheader{
    display: flex !important;
  }
.swiper_mobile_bg{
  height: 329px !important;
}


  .swiper_mobile_bg{
    height: 329px !important;
  }
  .font-semibold{
    font-size: 12px;
  }
  .modal-language .flag{
    height: 18px;
    width: 24px;
    margin-right: 4px;
  }
  .grid-container-home{
    display: grid;
    grid-template-columns: 230px 230px 230px;
    column-gap: 20px;
    
  }
  .item4{
    display: none;
  }
}

@media only screen and (max-width: 1200px){
  .skeleton{
    width: 130px !important;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 100px 100px 100px 100px;
    column-gap: 10px;
   
  }
}

  .card-number-display {
    width: 100%;
  }
  
  @media (max-width: 740px) {
    .card-number-display {
      width: 100%;
      padding: 0;
      margin: 0;
    }
    
    .StripeElement {
      width: 100%;
    }

    .check-btn{
      height: 98px;
      min-width: 90%;
    }

  }

  .centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .date-picker-container {
    display: flex; /* Make the container a flexbox */
    align-items: center; /* Align elements vertically */
  }
