.mypopup {
    text-align: center;
    padding: 20px;
    overflow-y: auto;
    position: "absolute";
    z-index: 1000;
  }
  
  .mypopup-trigger {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f0f0f0;
  }
  
  .mypopup-trigger:hover {
    background-color: #e0e0e0;
  }
  
  .mypopup {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    border: 1px solid black;
  }
  
  .mypopup h5 {
    margin-bottom: 15px;
  }

  .mypopup h6 {    
    font-weight: bold;
    margin-bottom: 10px;
  }

  .mypopup-close {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #333;
    color: #fff;
  }
  
  .mypopup-add {
    margin-left: 20px;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #489c45;
    color: #fff;
  }
  
  .mypopup-close:hover {
    background-color: #222;
  }

  .disabledListItem {
    opacity: 0.5;
    filter: grayscale(100%); /* Optional: Apply a grayscale filter */
  }
